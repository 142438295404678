import styled, { css } from "styled-components";
import { palette } from 'modules/defines/styles';

export const Container = styled.div`
  padding-top: 24px;
  .isMobile & {
    margin-top: 0px;
  }
  height: 100%;
`;

// <<<  테이블 위 제목 및 설명  >>>

export const Title = styled.div`
  text-align: left;
  font-size: 20px;
  color: ${palette.fontDefault};
  font-weight: 700;
  margin-bottom: 10px;
`;
export const SubTitle = styled.div`
  text-align: left;
  font-size: 12px;
  color: ${palette.fontDefault};
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 1.2;
`;
export const ButtonWrapper = styled.div`
  .isMobile & {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .isDesktop & {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    right: 45px;
    top: 55px;
    align-items: center;
    justify-content: center;
  }
`;
export const DividerTitle = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 0px;
  border-top: 1px solid ${palette.borderLightGrey};
  opacity: 1;
`;

// <<<  테이블  >>>
export const LayoutWrapper = styled.div`
  background-color: ${palette.white};
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  overflow: scroll;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  ${(props) =>
    props.isPaginated
      ? css`
        height: ${(props) =>
          props.viewTotal ? 'calc(100vh - 200px)' : 'auto'};
          `
      : css`
        height: calc(100vh - 200px);

      `
  }
  
  .isMobile & {
    margin-top: 0px;
    padding: 0;
    height: calc(100vh - 120px);
  }
  }
`;
export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

// <<<  테이블 위 헤더 레이아웃 >>>
export const ContainerHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  margin-bottom: 12px;
  .isMobile & {
    margin-bottom: 0px;
  }
`;
export const ContainerHeaderWrapperWrapper = styled.div`
  display:flex;
  flex-direction: row;
`;
export const ContainerHeader = styled.div`
  margin-left: 4px;
  font-size: 20px;
  color: ${palette.fontDefault};
  font-weight: 700;
  @media screen and (max-width: 1200px) {
    margin-bottom: 16px;
  }
  .isMobile & {
    font-size: 16px;
  }
`;
export const ContainerHeaderSubtext = styled.div`
  width: auto;
  padding: 0 10px;
  color: ${palette.fontDefault};
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px;
  height: 100%;
  align-items: center;
  display: flex;
    .isMobile & {
    font-size: 10px;
  }
`;

// <<< DETAIL 창에서 쓰이는 Style >>>
export const DivideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const DetailFormWrapper = styled.form`
  padding: 10px;
  width: 100%;
`;

// <<< Table Pagination >>>

export const PageContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: center;
`;
export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  margin: 0 8px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.current ? palette.fontBlue : palette.fontDefault)};
  &:hover {
    color: ${palette.fontBlueHover};
  }
  cursor: pointer;
  @media screen and (max-width: 820px) {
    padding: 0 4px;
    margin: 0 4px;
  }
`;

// <<< Loading 레이아웃  >>>
export const LoadingText = styled.div`
  font-size: 14px;
  line-height: 1.4;
  font-family: Pretendard-l;
  color: ${palette.mainBlue};
  .isMobile & {
    width: 100%;
    display:flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

// <<< TABLE 상단 툴바 >>>

export const TableToolBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    justify-content: space-between;
  }
`;
export const TableToolBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  margin-left: 12px;
`;

export const SearchTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${palette.fontDefault};
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const SearchInput = styled.input`
  width: ${(props) => (props.width ? props.width : '350px')};
  border: 1px solid ${palette.mint};
  border-radius: 5px;
  padding-left: 10px;
  .isMobile & {
    height: 30px;
  }
`;

export const MobileToolbarContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${(props) => (props.isClicked ? 'flex-end' : 'space-between')};
`;
export const MobileToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  overflow-x: scroll;
`;
export const MobileToolbarOpenButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 32px;
  background-color: ${palette.mainBlue};
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${palette.fontWhite};
  font-family: Pretendard-b;
  font-size: 10px;
`;

export const MobileAddSpaceBtn = styled.div`
  background-color: ${palette.mainBlue};
  &:hover{
    background-color: ${palette.mainBlueHover};
  }
  width: 30px;
  height: 30px;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${palette.fontWhite};
  font-family: Pretendard-b;
  font-size: 10px;
`;
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { spaceAPI } from 'api';
import useAxios from 'modules/customHooks/useAxios';
import { UserContext } from 'modules/api/user';
import { GlobalContext } from 'modules/context/GlobalContext';
import { Container } from 'components/common/DataTableStyle';
import SpaceDetailFilter from 'components/space/SpaceDetailFilter';
import SpaceDetailInfo from 'components/space/SpaceDetailInfo';
import SpaceDetailImage from 'components/space/SpaceDetailImage';
import DetailKeyword from 'components/DetailKeyword';
import { ModalContext } from 'modules/context/ModalContext';
import PanoramaModal from 'components/modal/PanoramaModal';
import PanoramaNew from 'components/modal/PanoramaNew';
import {
  LayoutWrapper,
  CommonContainer,
  CommonWrapper,
  LayoutNoneWrapper,
  NoneTitle,
  NoneDesc,
} from 'components/space/SpaceCommon';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const SpaceDetailContainer = () => {
  const [userContext] = useContext(UserContext);
  const globalState = useContext(GlobalContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const categoryData = globalState.category;
  const keywordAllData = globalState.keyword;

  // 반응형 UI 대응
  const [isOneColumn, setIsOneColumn] = useState(false);
  const [mode, setMode] = useState(1); // 1 : 공간정보/이미지 or 2 : 필터/키워드

  const refetch = () => {
    globalState.refetch('keyword');
  };
  const filterAllData = globalState.filter;
  // Space ID
  const { id } = useParams();
  const [titleList, setTitleList] = useState([]);
  useEffect(() => {
    let titleArray = [];
    globalState.space.forEach((space) => titleArray.push(space.title));
    setTitleList(titleArray);
  }, []);

  // Pagination Resp
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsOneColumn(true);
      } else {
        setIsOneColumn(false);
      }
    };
    // 이벤트 리스너 등록
    window.addEventListener('resize', handleResize);
    // 초기 렌더링에서 한 번 실행
    handleResize();
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // API GET : 공간 정보
  const {
    loading: detailLoading,
    data: detailData,
    refetch: detailRefetch,
  } = useAxios(spaceAPI.spaceDetail, id);

  const havePermission =
    userContext.user.role.match('admin') !== null ||
    userContext.user.user_id === detailData?.data?.owner;

  const isSuperAdmin = userContext.user.role === 'super-admin';

  // API GET : Get Connected Keyword
  const {
    loading: keywordConnectedLoading,
    data: keywordConnectedData,
    refetch: keywordConnectedRefetch,
  } = useAxios(spaceAPI.spaceKeyword, id);
  // API GET : Get Connected Filter
  const {
    loading: filterConnectedLoading,
    data: filterConnectedData,
    refetch: filterConnectedRefetch,
  } = useAxios(spaceAPI.spaceFilter, id);

  // API GET : 공간 이미지
  const {
    loading: imageDataLoading,
    data: imageData,
    refetch: imageDataRefetch,
  } = useAxios(spaceAPI.getImage, id);

  const {
    loading: videoDataLoading,
    data: videoData,
    refetch: videoDataRefetch,
  } = useAxios(spaceAPI.getVideo, id);

  const openModalPanorama = async (e) => {
    e.preventDefault();
    return openModal(
      <PanoramaModal
        spaceId={id}
        closeCurrentModal={closeModal}
        defaultFloor={detailData?.data.floor_default}
      />
    );
  };

  const openModalPanoramaNew = async (e) => {
    e.preventDefault();
    return openModal(
      <PanoramaNew
        spaceId={id}
        closeCurrentModal={closeModal}
        defaultFloor={detailData?.data.floor_default}
      />
    );
  };

  return (
    <Container>
      {detailData !== null && !detailLoading ? (
        <>
          {isOneColumn && (
            <ButtonWrapper>
              <PageButton active={mode === 1} onClick={() => setMode(1)}>
                정보/이미지
              </PageButton>
              <PageButton active={mode === 2} onClick={() => setMode(2)}>
                필터/키워드
              </PageButton>
            </ButtonWrapper>
          )}

          <LayoutWrapper fromBottom="100px">
            {(!isOneColumn || (isOneColumn && mode === 1)) && (
              <CommonContainer>
                {/* 매장 정보 */}
                <CommonWrapper>
                  <SpaceDetailInfo
                    s_id={id}
                    havePermission={havePermission}
                    detailLoading={detailLoading}
                    detailData={detailData}
                    detailRefetch={detailRefetch}
                    titleList={titleList}
                    categoryData={categoryData}
                    isSuperAdmin={isSuperAdmin}
                  />
                </CommonWrapper>
                {/* 이미지 & XR Viewer */}
                <CommonWrapper>
                  {!imageDataLoading && (
                    <SpaceDetailImage
                      s_id={id}
                      havePermission={havePermission}
                      imageDataLoading={imageDataLoading}
                      imageData={imageData}
                      imageDataRefetch={imageDataRefetch}
                      videoDataLoading={videoDataLoading}
                      videoData={videoData}
                      videoDataRefetch={videoDataRefetch}
                      openModalPanorama={openModalPanorama}
                      openModalPanoramaNew={openModalPanoramaNew}
                    />
                  )}
                </CommonWrapper>
              </CommonContainer>
            )}

            {(!isOneColumn || (isOneColumn && mode === 2)) && (
              <CommonContainer>
                {/* 매장 필터 */}
                <CommonWrapper>
                  {!filterConnectedLoading && (
                    <SpaceDetailFilter
                      havePermission={havePermission}
                      s_id={id}
                      filterAllData={filterAllData}
                      filterConnectedLoading={filterConnectedLoading}
                      filterConnectedData={filterConnectedData}
                      filterConnectedRefetch={filterConnectedRefetch}
                    />
                  )}
                </CommonWrapper>

                {/* 매장 키워드 */}
                <CommonWrapper>
                  {!keywordConnectedLoading && (
                    <DetailKeyword
                      havePermission={havePermission}
                      typeData="space"
                      id={id}
                      keywordAllData={keywordAllData}
                      keywordAllRefetch={refetch}
                      keywordConnectedLoading={keywordConnectedLoading}
                      keywordConnectedData={keywordConnectedData.data}
                      keywordConnectedRefetch={keywordConnectedRefetch}
                    />
                  )}
                </CommonWrapper>
              </CommonContainer>
            )}
          </LayoutWrapper>
        </>
      ) : (
        <LayoutNoneWrapper>
          <NoneTitle>데이터 로딩중입니다...</NoneTitle>
          <NoneDesc>본 화면이 지속되면 id값을 확인해주세요.</NoneDesc>
        </LayoutNoneWrapper>
      )}
    </Container>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 10px 10px 10px;
`;
const PageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  margin: 0px 5px;
  background-color: ${(props) =>
    props.active ? palette.mainBlue : palette.mainDisabled};
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
  cursor: pointer;
  color: ${palette.fontWhite};
  font-size: 12px;
  font-family: Pretendard-sb;
  border-radius: 8px;
`;

export default SpaceDetailContainer;

export function refreshPage() {
  window.location.reload();
}

export const getComplementaryColor = (hexColor) => {
  // hex 색상에서 '#'을 제거합니다.
  hexColor = hexColor.replace('#', '');

  // RGB 값을 계산합니다.
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // RGB 채널을 반전시킵니다.
  const rComplement = (255 - r).toString(16).padStart(2, '0');
  const gComplement = (255 - g).toString(16).padStart(2, '0');
  const bComplement = (255 - b).toString(16).padStart(2, '0');

  // 반전된 RGB 값을 hex 형식으로 변환하여 반환합니다.
  return `#${rComplement}${gComplement}${bComplement}`;
};
